//@ts-check
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import ProductItem from "../../components/ProductItem";
import ProductPage from "../../components/ProductPage";
import Search, { filter } from "../../components/Search";

const Page = () => {
  const { doruk: all } = useStaticQuery(
    graphql`
      query {
        doruk: allItem(filter: { model: { regex: "/(Doruk|DORUK|doruk)/" } }) {
          edges {
            node {
              name
              id
              model
              code
              price
              gatsbyImage {
                childImageSharp {
                  gatsbyImageData(
                    #formats: [AVIF, WEBP, PNG]
                    #placeholder: DOMINANT_COLOR
                    aspectRatio: 1.6
                    #layout: CONSTRAINED
                    #quality: 25
                  )
                }
              }
            }
          }
        }
      }
    `
  );
  const [value, setValue] = React.useState("");
  const [data, setData] = useState(all.edges);
  useEffect(() => {
    let filtered = filter(value, all.edges);
    setData(filtered);
  }, [value]);
  const handleChange = (val) => setValue(val);
  return (
    <ProductPage>
      <Search value={value} setValue={handleChange} />
      {data.map(({ node }) => (
        <ProductItem
          key={node.id + "p"}
          id={node.id}
          gatsbyImage={node.gatsbyImage}
          name={node.name}
          model={node.model}
          price={node.price}
        ></ProductItem>
      ))}
    </ProductPage>
  );
};

export default Page;
